export const TRANSLATIONS = {
  'en': {
      'panicButtonString': 'Hold button for 3 seconds to take 24-hour timeout',
  },
  'zh-hk': {
      'panicButtonString': '长按3秒24小时即时锁定',
  },
  'fr': {
      'panicButtonString': 'Maintenez le bouton enfoncé pendant 3 secondes pour prendre un verrouillage instantané de 24 heures',
  },
  'ro': {
      'panicButtonString': 'Tine apasat 3 secunde pentru a lua o pauza de 24h',
  },
  'es': {
      'panicButtonString': 'Dar clic durante 3 segundos para tomar un descanso de 24 horas',
  },
  'de': {
      'panicButtonString': 'Button für 3 Sekunden drücken um eine 24-stündige Auszeit zu nehmen',
  },
  'hr': {
      'panicButtonString': 'Držite gumb 3 sekunde kako biste aktivirali vremensko ograničenje od 24 sata',
  },
  'sl': {
      'panicButtonString': 'Držite gumb 3 sekunde, da bi vzeli 24 urni premor',
  },
  'pl': {
      'panicButtonString': 'Przytrzymaj przycisk przez 3 sekundy, aby uzyskać 24-godzinny limit czasu',
  },
}
