<svelte:options tag={null} />

<script lang="ts">
    
  import { getDevice } from 'rvhelper';

  export let sponsorurl:string = '';
  export let sponsorimage:string = '';
  export let sponsortitle:string = '';
  export let paymenturl:string = '';
  export let paymentimage:string = '';
  export let paymenttitle:string = '';
  export let vendorurl:string = '';
  export let vendorimage:string = '';
  export let vendortitle:string = '';
  export let helperlinkurl:string = '';
  export let helperlinkimage:string = '';
  export let helperlinktitle:string = '';
  export let licenseurl:string = '';
  export let licenseimage:string = '';
  export let licensetitle:string = '';
  export let sociallinkurl:string = '';
  export let sociallinkimage:string = '';
  export let sociallinktitle:string = '';
  export let target:string = '_self';
  export let linktype:string = 'false';
  export let displaycolumn:string = '';
  export let baseurl:string = '';
  export let lang:string = '';
  export let clientstyling:string = '';
  export let clientstylingurl:string = '';

  let customStylingContainer:HTMLElement;
  let userAgent = window.navigator.userAgent;
  let device:string = getDevice(userAgent);

  const setClientStyling = () => {
    let sheet = document.createElement('style');
    sheet.innerHTML = clientstyling;
    customStylingContainer.appendChild(sheet);
  }

  const setClientStylingURL = ():void => {
    let url:URL = new URL(clientstylingurl);
    let cssFile:HTMLElement = document.createElement('style');

    fetch(url.href)
      .then((res:any) => res.text())
      .then((data:any) => {
        cssFile.innerHTML = data

        setTimeout(() => { customStylingContainer.appendChild(cssFile) }, 1);
      });
  }

  const openLinkNativeDevices = (url:string, type:string, target:string) => {
    if (type) { // if link is external, inform FE
      window.postMessage({ type:'ExternalLinkNavigation', externalUrl: url, target: target }, window.location.href);
    } else { // if link is internal, inform FE
      window.postMessage({ type:'LinkNavigation', navUrl: url, target: target }, window.location.href);
    }
  }

  $: clientstyling && customStylingContainer && setClientStyling();
  $: clientstylingurl && customStylingContainer && setClientStylingURL();
</script>

<div class="ThumbnailContainer" part="ThumbnailContainer" bind:this={customStylingContainer}>
  {#if device == 'PC'} <!-- Case when we need anchor navigation in order to control the way a link opens inside an iframe through the target attribute -->
    {#if sponsorimage}
      <div class="FooterItem SponsorItem" part="FooterItem SponsorItem">
        <a class="{sponsortitle ? 'FooterSponsorLink' : '' } {sponsorurl ? '' : 'FooterLinkDisabled'} FooterItemAnchor"
          part="{sponsortitle ? 'FooterSponsorLink' : '' } {sponsorurl ? '' : 'FooterLinkDisabled'} FooterItemAnchor"
          href={linktype ? sponsorurl : baseurl + `/${lang}/` + sponsorurl}
          target={target}>
          <img class="FooterImage" part="FooterImage" src={sponsorimage} title={sponsortitle|| sponsorurl} />
          {#if sponsortitle}
            <p class="SponsorTitle" part="SponsorTitle">{sponsortitle}</p>
          {/if}
        </a>
      </div>
    {:else if paymentimage}
      <div class="FooterItem PaymentItem" part="FooterItem PaymentItem">
        <a class="{paymenttitle ? 'FooterPaymentLink' : '' } {paymenturl ? '' : 'FooterLinkDisabled'} FooterItemAnchor"
          part="{paymenttitle ? 'FooterPaymentLink' : '' } {paymenturl ? '' : 'FooterLinkDisabled'} FooterItemAnchor"
          href={linktype ? paymenturl : baseurl + `/${lang}/` + paymenturl}
          target={target}>
          <img class="FooterImage" part="FooterImage" src={paymentimage} title={paymenttitle || paymenturl} />
          {#if paymenttitle}
            <p class="PaymentTitle" part="PaymentTitle">{paymenttitle}</p>
          {/if}
        </a>
      </div>
    {:else if vendorimage}
      <div class="FooterItem GameVendorItem" part="FooterItem GameVendorItem">
        <a class="{vendorurl ? '' : 'FooterLinkDisabled'} FooterItemAnchor"
          part="{vendorurl ? '' : 'FooterLinkDisabled'} FooterItemAnchor"
          href={linktype ? vendorurl : baseurl + `/${lang}/` + vendorurl}
          target={target}>
          <img class="FooterImage" part="FooterImage" src={vendorimage} title={vendortitle || vendorurl} />
        </a>
      </div>
    {:else if helperlinkimage}
      <div class="FooterItem HelperLinkItem {displaycolumn == "true" ? "DisplayColumn" : ""}" part="FooterItem HelperLinkItem">
        <a class="{helperlinktitle ? 'FooterHelperLink' : '' } {helperlinkurl ? '' : 'FooterLinkDisabled'} FooterItemAnchor"
          part="{helperlinktitle ? 'FooterHelperLink' : '' } {helperlinkurl ? '' : 'FooterLinkDisabled'} FooterItemAnchor"
          href={linktype ? helperlinkurl : baseurl + `/${lang}/` + helperlinkurl}
          target={target}>
          <img class="FooterImage" part="FooterImage" src={helperlinkimage} title={helperlinktitle || helperlinkurl} />
          {#if helperlinktitle}
            <p class="HelperTitle" part="HelperTitle">{helperlinktitle}</p>
          {/if}
        </a>
      </div>
    {:else if licenseimage}
      <div class="FooterItem" part="FooterItem">
        <a class="FooterLink {licenseurl ? '' : 'FooterLinkDisabled'} FooterItemAnchor"
          part="FooterLink {licenseurl ? '' : 'FooterLinkDisabled'} FooterItemAnchor"
          href={linktype ? licenseurl : baseurl + `/${lang}/` + licenseurl}
          target={target}>
          <img class="FooterImage" part="FooterImage" src={licenseimage} title={licensetitle || licenseurl} />
        </a>
      </div>
    {:else if sociallinkimage}
      <div class="FooterItem SocialItem" part="FooterItem SocialItem">
        <a class="FooterLink {sociallinkurl ? '' : 'FooterLinkDisabled'} FooterItemAnchor"
          part="FooterLink {sociallinkurl ? '' : 'FooterLinkDisabled'} FooterItemAnchor"
          href={linktype ? sociallinkurl : baseurl + `/${lang}/` + sociallinkurl}
          target={target}>
          <img class="FooterImage" part="FooterImage" src={sociallinkimage} title={sociallinktitle || sociallinkurl} />
        </a>
      </div>
    {/if}
  {:else} <!-- Case when external navigation is handled by native devices -->
    {#if sponsorimage}
      <div class="FooterItem SponsorItem" part="FooterItem SponsorItem">
        <button class="{sponsortitle ? 'FooterSponsorLink' : '' } {sponsorurl ? '' : 'FooterLinkDisabled'} FooterItemAnchor"
                on:click={() => openLinkNativeDevices(linktype ? sponsorurl : baseurl + `/${lang}/` + sponsorurl, linktype, target)}>
          <img class="FooterImage" part="FooterImage" src={sponsorimage} title={sponsortitle|| sponsorurl} />
          {#if sponsortitle}
            <p class="SponsorTitle" part="SponsorTitle">{sponsortitle}</p>
          {/if}
        </button>
      </div>
    {:else if paymentimage}
      <div class="FooterItem PaymentItem" part="FooterItem PaymentItem">
        <button class="{paymenttitle ? 'FooterPaymentLink' : '' } {paymenturl ? '' : 'FooterLinkDisabled'} FooterItemAnchor"
                on:click={() => openLinkNativeDevices(linktype ? paymenturl : baseurl + `/${lang}/` + paymenturl, linktype, target)}>
          <img class="FooterImage" part="FooterImage" src={paymentimage} title={paymenttitle || paymenturl} />
          {#if paymenttitle}
            <p class="PaymentTitle" part="PaymentTitle">{paymenttitle}</p>
          {/if}
        </button>
      </div>
    {:else if vendorimage}
      <div class="FooterItem GameVendorItem" part="FooterItem GameVendorItem">
        <button class="{vendorurl ? '' : 'FooterLinkDisabled'} FooterItemAnchor"
                on:click={() => openLinkNativeDevices(linktype ? vendorurl : baseurl + `/${lang}/` + vendorurl, linktype, target)}>
          <img class="FooterImage" part="FooterImage" src={vendorimage} title={vendortitle || vendorurl} />
        </button>
      </div>
    {:else if helperlinkimage}
      <div class="FooterItem HelperLinkItem {displaycolumn == "true" ? "DisplayColumn" : ""}" part="FooterItem HelperLinkItem">
        <button class="{helperlinktitle ? 'FooterHelperLink' : '' } {helperlinkurl ? '' : 'FooterLinkDisabled'} FooterItemAnchor"
                on:click={() => openLinkNativeDevices(linktype ? helperlinkurl : baseurl + `/${lang}/` + helperlinkurl, linktype, target)}>
          <img class="FooterImage" part="FooterImage" src={helperlinkimage} title={helperlinktitle || helperlinkurl} />
          {#if helperlinktitle}
            <p class="HelperTitle" part="HelperTitle">{helperlinktitle}</p>
          {/if}
        </button>
      </div>
    {:else if licenseimage}
      <div class="FooterItem" part="FooterItem">
        <button class="FooterLink {licenseurl ? '' : 'FooterLinkDisabled'} FooterItemAnchor"
                on:click={() => openLinkNativeDevices(linktype ? licenseurl : baseurl + `/${lang}/` + licenseurl, linktype, target)}>
          <img class="FooterImage" part="FooterImage" src={licenseimage} title={licensetitle || licenseurl} />
        </button>
      </div>
    {:else if sociallinkimage}
      <div class="FooterItem SocialItem" part="FooterItem SocialItem">
        <button class="FooterLink {sociallinkurl ? '' : 'FooterLinkDisabled'} FooterItemAnchor"
                on:click={() => openLinkNativeDevices(linktype ? sociallinkurl : baseurl + `/${lang}/` + sociallinkurl, linktype, target)}>
          <img class="FooterImage" part="FooterImage" src={sociallinkimage} title={sociallinktitle || sociallinkurl} />
        </button>
      </div>
    {/if}
  {/if}
</div>

<style lang="scss">
  :host {
    font-family: system-ui, -apple-system, Roboto, Helvetica;
  }

  .FooterItem {
    display: inline-block;
    padding: 0 12px;
    .FooterSponsorLink, .FooterPaymentLink {
      display: block;
    }
    .FooterSponsorLink {
      display: flex;
      align-items: center;
    }
    .FooterPaymentLink, .FooterHelperLink {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .FooterLinkDisabled {
      pointer-events: none;
      cursor: auto;
    }
    .SponsorTitle, .PaymentTitle {
      font-size: 9px;
      text-decoration: none;
      color: var(--emfe-w-color-gray-150, #828282);
      text-align: center;
    }
    .HelperTitle {
      color: #111111;
      font-size: 8px;
    }
    &.HelperLinkItem {
      p {
        font-size: 10px;
      }
    }
    .FooterItemAnchor {
      display: flex;
      flex-direction: column;
      gap: 6px;
      text-decoration: none;
      cursor: pointer;
      border: none;
      background: transparent;
      padding: 0;
    }
    &.SocialItem {
      .FooterItemAnchor {
        width: 42px;
        height: 42px;
      }
    }
    .FooterImage {
      min-height: 38px;
    }
  }

  .SponsorTitle {
    margin: 0;
  }

  .FooterLink {
    display: block;
    text-align: center;
  }
  .DisplayColumn {
    .FooterItemAnchor {
      flex-direction: row;
    }
    .FooterImage {
      height: 16px;
      width: 16px;
      min-height: unset;
    }
    &.FooterItem.HelperLinkItem p {
      font-size: 12px;
    }
  }


  @media only screen and (max-width: 768px) {
    .FooterItem {
      padding: 0 5px;
      &.PaymentItem {
        padding: 15px 5px;
      }
      &.GameVendorItem {
        padding: 0 10px;
      }
    }
  }
</style>
